<!--
 * @Description: 企业
 * @Author: luocheng
 * @Date: 2021-11-05 16:18:17
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-06-07 16:42:03
-->
<template>
	<div class="enterprise" v-loading="!isInited">
		<SearchBox v-model="archiName"></SearchBox>
		<article class="enterprise-main" v-if="isInited">
			<!-- <section class="group item">
				<GroupItem
					:title="'集团'"
					:stylObj="styleObj"
				></GroupItem>
			</section> -->
			<section class="company item">
				<GroupItem
					:dataList="companyRenderData || []"
					:current="targetId"
					:title="$t('enterprise.Index.766519-0')"
					:stylObj="styleObj"
				></GroupItem>
			</section>
			<section class="company item">
				<!-- 信息 -->
				<ArchiInfo :reload="reload"></ArchiInfo>
			</section>
		</article>
	</div>
</template>

<script>
import SearchBox from '../SearchBox';
import GroupItem from './GroupItem';
import ArchiInfo from '../ArchiInfo';
import { mapState } from 'vuex';
import { filterTreeData } from '@/utils/tools';

export default {
	name: 'Enterprise',
	components: {
		SearchBox,
		GroupItem,
		ArchiInfo
	},
	props: {
		reload: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	data() {
		return {
			styleObj: {
				height: '412px'
			},
			// 公司数据
			companyData: [],
			targetId: null,
			// 架构名称筛选
			archiName: '',
			companyRenderData: [],
			isInited: false
		};
	},
	created() {
		this.companyData = this.formatData(JSON.parse(localStorage.getItem('myCompany')) || []);
		this.targetId = this.$GetTargetArchi("id");
		this.companyRenderData = this.companyData;
		this.isInited = true;
	},
	computed: {
		// companyRenderData() {
		// 	return filterTreeData(this.companyData, this.archiName);
		// 	// return this.companyData.filter(ele => {
		// 	// 	const name = ele.name || ele.short_name || '';
		// 	// 	return name.includes(this.archiName || '');
		// 	// })
		// },
		...mapState([
			'cacheArchi',
			'targetArchi'
		]),
		// 架构信息
		archiInfo() {
			return this.cacheArchi || this.targetArchi;
		}
	},
	watch: {
		companyData: {
			handler() {
				this.companyRenderData = filterTreeData(this.companyData, this.archiName);
			},
			deep: true,
			immediate: true
		},
		archiName() {
			this.isInited = false;
			this.companyRenderData = filterTreeData(this.companyData, this.archiName);
			this.isInited = true;
		},
		companyRenderData: {
			handler() {
				// 将最右侧改为默认选中一个，逻辑为有当前项目则选中当前项目，没有则默认选中一个 @boss@晓雯 2023.4.6
				if (!this.companyRenderData.length) return;
				if (this.companyRenderData.find(ele => ele.id === this.archiInfo.id)) {
					return;
				}
				this.$store.commit('setCacheArchi', this.companyRenderData[0] || null);
			},
			deep: true,
			immediate: true
		}
	},
	methods: {
    /**
     * @description: 格式化数据
     * @param {Array} list 数据列表
     * @return {Array} 结果
     */
		formatData(list) {
      const result = []; // 存放结果集
      const itemMap = {}; //
      for (const item of list) {
        const id = item.data_id;
        const parentId = item.parent_id;
        if (!itemMap[id]) {
          itemMap[id] = {
            children: [],
          };
        }
        itemMap[id] = {
          ...item,
          children: itemMap[id]["children"],
        };
        const treeItem = itemMap[id];
        if (+parentId === 0) {
          result.push(treeItem);
        } else {
          if (!itemMap[parentId]) {
            itemMap[parentId] = {
              children: [],
            };
          }
          itemMap[parentId].children.push(treeItem);
        }
      }
      // 处理无上级任职，当前类表中不存在parent_id为0的项目
      if (!result.length) {
        return list;
      }
      return result;
    },
	}
};
</script>

<style lang="less" scoped>
@itemWidth: 252px;
.enterprise {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	.enterprise-main {
		flex: 1;
		overflow: hidden;
		display: flex;
		justify-content: space-between;
		box-sizing: border-box;
		padding-top: 16px;
		height: 412px;
		.item {
			width: @itemWidth;
			height: 100%;
			border-radius: 4px;
			overflow: hidden;
      &.company{
        // flex: 1;
      }
		}
	}
}
</style>