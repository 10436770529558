<!--
 * @Author: hw
 * @Date: 2024-03-20 17:09:54
 * @LastEditors: hw 315249041@qq.com
 * @LastEditTime: 2024-04-08 17:06:58
 * @FilePath: \dataview-viewer-test\src\views\newSecond\components\translateSwitch\index.vue
-->
<template>
  <section class="logo-header-wrap">
    <div class="language-switch" @click.stop="changeLanguage">
      <transition v-if="status === 'main'" name="fade" mode="out-in">
        <svg v-if="langValue === 'en'" xmlns="http://www.w3.org/2000/svg" width="26" key="1" height="26" viewBox="0 0 26 26" fill="none">
          <path opacity="0.28" d="M19.829 16.6622H23.4736C24.407 16.6622 24.8736 17.1288 24.8736 18.0622V23.5782C24.8736 24.5115 24.407 24.9782 23.4736 24.9782H19.829C18.8956 24.9782 18.429 24.5115 18.429 23.5782V18.0622C18.429 17.1288 18.8956 16.6622 19.829 16.6622Z" fill="#8991A2"/>
          <path d="M17.8061 13.7741H18.5461V15.5741H22.1361V20.2741H21.4161V19.6441H18.5461V23.0541H17.8061V19.6441H14.9461V20.2741H14.2261V15.5741H17.8061V13.7741ZM14.9461 18.9441H17.8061V16.2741H14.9461V18.9441ZM18.5461 18.9441H21.4161V16.2741H18.5461V18.9441Z" fill="#8991A2"/>
          <rect x="11.1169" y="11.1175" width="13.75" height="13.75" rx="1" stroke="#8991A2"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0.633179 2.1325C0.633179 1.30407 1.30475 0.6325 2.13318 0.6325H13.8832C14.7116 0.6325 15.3832 1.30407 15.3832 2.1325V13.8825C15.3832 14.7109 14.7116 15.3825 13.8832 15.3825H2.13318C1.30475 15.3825 0.633179 14.7109 0.633179 13.8825V2.1325ZM13.0453 4.86639V11.2648H12.1927L8.97156 6.32977V11.2648H8.12341V4.86639H8.97156L12.2059 9.81463V4.86639H13.0453ZM7.07312 11.2648V10.5749H3.85632V8.3073H6.62488V7.61736H3.85632V5.56072H7.02917V4.86639H3.85632H3.68494H3.00818V11.2648H3.68494H3.85632H7.07312Z" fill="#8991A2"/>
        </svg>
  
        <svg v-else xmlns="http://www.w3.org/2000/svg" width="26" height="26" key="2" viewBox="0 0 26 26" fill="none">
          <path opacity="0.28" d="M9.83897 6.67194H13.4836C14.417 6.67194 14.8836 7.1386 14.8836 8.07194V13.5879C14.8836 14.5213 14.417 14.9879 13.4836 14.9879H9.83897C8.90563 14.9879 8.43896 14.5213 8.43896 13.5879V8.07194C8.43896 7.1386 8.90563 6.67194 9.83897 6.67194Z" fill="#8991A2"/>
          <path d="M13.0205 4.80304V11.2015H12.168L8.94678 6.26642V11.2015H8.09863V4.80304H8.94678L12.1812 9.75128V4.80304H13.0205Z" fill="#8991A2"/>
          <path d="M7.04834 10.5115V11.2015H3.66016V10.5115H7.04834ZM3.83154 4.80304V11.2015H2.9834V4.80304H3.83154ZM6.6001 7.55402V8.24396H3.66016V7.55402H6.6001ZM7.00439 4.80304V5.49738H3.66016V4.80304H7.00439Z" fill="#8991A2"/>
          <rect x="1.12695" y="1.12726" width="13.75" height="13.75" rx="1" stroke="#8991A2"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12.123 10.6227C11.2946 10.6227 10.623 11.2943 10.623 12.1227V23.8727C10.623 24.7012 11.2946 25.3727 12.123 25.3727H23.873C24.7015 25.3727 25.373 24.7012 25.373 23.8727V12.1227C25.373 11.2943 24.7015 10.6227 23.873 10.6227H12.123ZM18.32 14.0001H17.58V15.8001H14V20.5001H14.72V19.8701H17.58V23.2801H18.32V19.8701H21.19V20.5001H21.91V15.8001H18.32V14.0001ZM17.58 19.1701H14.72V16.5001H17.58V19.1701ZM21.19 19.1701H18.32V16.5001H21.19V19.1701Z" fill="#8991A2"/>
        </svg>
      </transition>

      <transition v-else name="fade" mode="out-in">
        <svg v-if="langValue === 'en'"  xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
          <path opacity="0.28" d="M19.8289 16.6621H23.4736C24.4069 16.6621 24.8736 17.1288 24.8736 18.0621V23.5781C24.8736 24.5114 24.4069 24.9781 23.4736 24.9781H19.8289C18.8956 24.9781 18.4289 24.5114 18.4289 23.5781V18.0621C18.4289 17.1288 18.8956 16.6621 19.8289 16.6621Z" fill="white"/>
          <path d="M17.806 13.774H18.546V15.574H22.136V20.274H21.416V19.644H18.546V23.054H17.806V19.644H14.946V20.274H14.226V15.574H17.806V13.774ZM14.946 18.944H17.806V16.274H14.946V18.944ZM18.546 18.944H21.416V16.274H18.546V18.944Z" fill="white"/>
          <rect x="11.1169" y="11.1174" width="13.75" height="13.75" rx="1" stroke="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0.633118 2.13245C0.633118 1.30402 1.30469 0.632446 2.13312 0.632446H13.8831C14.7115 0.632446 15.3831 1.30402 15.3831 2.13245V13.8824C15.3831 14.7109 14.7115 15.3824 13.8831 15.3824H2.13312C1.30469 15.3824 0.633118 14.7109 0.633118 13.8824V2.13245ZM13.0452 4.86633V11.2648H12.1927L8.9715 6.32971V11.2648H8.12335V4.86633H8.9715L12.2059 9.81458V4.86633H13.0452ZM7.07306 11.2648V10.5748H3.85626V8.30725H6.62482V7.61731H3.85626V5.56067H7.02911V4.86633H3.85626H3.68488H3.00812V11.2648H3.68488H3.85626H7.07306Z" fill="white"/>
        </svg>
        <svg v-else xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
          <path opacity="0.28" d="M9.83903 6.67188H13.4837C14.417 6.67188 14.8837 7.13854 14.8837 8.07188V13.5879C14.8837 14.5212 14.417 14.9879 13.4837 14.9879H9.83903C8.90569 14.9879 8.43903 14.5212 8.43903 13.5879V8.07188C8.43903 7.13854 8.90569 6.67188 9.83903 6.67188Z" fill="white"/>
          <path d="M13.0206 4.80298V11.2014H12.168L8.94684 6.26636V11.2014H8.09869V4.80298H8.94684L12.1812 9.75122V4.80298H13.0206Z" fill="white"/>
          <path d="M7.0484 10.5115V11.2014H3.66022V10.5115H7.0484ZM3.8316 4.80298V11.2014H2.98346V4.80298H3.8316ZM6.60016 7.55396V8.2439H3.66022V7.55396H6.60016ZM7.00446 4.80298V5.49731H3.66022V4.80298H7.00446Z" fill="white"/>
          <rect x="1.12701" y="1.1272" width="13.75" height="13.75" rx="1" stroke="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12.123 10.6227C11.2946 10.6227 10.623 11.2943 10.623 12.1227V23.8727C10.623 24.7011 11.2946 25.3727 12.123 25.3727H23.873C24.7015 25.3727 25.373 24.7011 25.373 23.8727V12.1227C25.373 11.2943 24.7015 10.6227 23.873 10.6227H12.123ZM18.32 14H17.58V15.8H14V20.5H14.72V19.87H17.58V23.28H18.32V19.87H21.19V20.5H21.91V15.8H18.32V14ZM17.58 19.17H14.72V16.5H17.58V19.17ZM21.19 19.17H18.32V16.5H21.19V19.17Z" fill="white"/>
        </svg>
      </transition>
    </div> 
  </section>
</template>

<script>
import actions from '@/libs/action';
  export default {
    name: 'TranslateSwitch',
    props: {
      status: {
        type: String,
        value: ['main','secondary'],
        default: 'main'
      }
    },
    data() {
      return {
        langList: [
          {
            label: 'EN',
            value: 'en'
          },
          {
            label: '中',
            value: 'zh'
          }
        ],
        langValue: '',
        lang: ''
      }
    },
    methods: {
      changeLanguage () {
        this.langValue = this.langValue === 'zh' ? 'en' : 'zh'
        localStorage.setItem('preferred_lang', this.langValue);
        this.$i18n.locale = this.langValue;
        document.documentElement.lang = this.langValue;
        actions.setGlobalState({
          lang: this.langValue
        });
        this.$QIANKUNActions.setGlobalState({
          lang: this.langValue
        });
				const root = document.querySelector(':root');
        
				if(this.langValue === 'en'){
					root.style.setProperty('--systemFontFamily', 'Roboto');
					document.querySelector('body').style.fontFamily = 'Roboto'
				} else {
					root.style.setProperty('--systemFontFamily', 'PingFangSC, PingFangSC-Medium');
					document.querySelector('body').style.fontFamily = 'PingFangSC, PingFangSC-Medium'
				}
      },
    },
    created() {
      // 设置switch语言
      this.langValue = this.$i18n.locale || localStorage.getItem('preferred_lang') || 'zh';
      document.documentElement.lang = this.langValue;
    }
  }
</script>

<style lang="less" scoped>
  .logo-header-wrap{
    height: 60px;

    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    user-select: none;
    .logo-images{
      height: 100%;
      display: flex;
      align-items: center;
      gap: 14px;
      .logo-item{
        height: 100%;
        width: auto;
        object-fit: fill;
      }
    }
    .language-switch{
      display: flex;
      width: 32px;
      height: 32px;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
  .fade-enter-active {
    transition: all .3s ease;
    opacity: 1;
  }
  .fade-leave-active {
    transition: all .2s linear;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0.5;
  }
</style>