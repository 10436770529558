<!--
 * @Description: 协作单位显示
 * @Author: luocheng
 * @Date: 2022-07-04 17:37:06
 * @LastEditors: hw 315249041@qq.com
 * @LastEditTime: 2024-03-20 15:17:30
-->
<template>
  <div class="cooperation">
    <section class="info-desc-box" @click="onToggleShow"
      :class="{
        'active': showTypes && typeList
      }"
    >
      <section class="base-info" v-if="typeForShow">
        <img :src="typeForShow.icon" alt="" class="icon">
        <p class="label">{{ typeForShow.label || '' }}</p>
      </section>
      <el-tag type="info" v-else>{{ $t('components.Cooperation.059629-0') }}</el-tag>
		</section>
    <!-- 内容 -->
    <!-- 283px -->
		<article class="cooperation-menu" :style="{ height: showTypes && typeList ? '215px' : '0px' }">
      <!-- 头像 -->
      <ul class="cooperations">
        <section class="cooperation-section">
          <li class="cooperation-item"
            v-for="(item, index) in typeList"
            :key="index"
          >
            <span class="status" :class="{
              'project-width': item.isActive
            }"></span>
            <p class="label">{{ item.label }}</p>
          </li>
        </section>
      </ul>
		</article>
		<!-- mask -->
		<div class="cooperation-mask" v-show="showTypes" @click.self="onToggleShow"></div>
  </div>
</template>

<script>
import { Tag } from 'element-ui';
import { mapState } from 'vuex';

export default {
  name: 'Cooperation',
  components: {
    'el-tag': Tag
  },
  data() {
    return {
      showTypes: false,
      originData: [
        {
          label: this.$t('components.Cooperation.059629-1'),
          icon: "https://static.bimcc.com/openim/yezhu9192D373663BDAEBAE8279E52FB0002F.png",
          isActive: false,
          code: 1
        },
        {
          label: this.$t('components.Cooperation.059629-2'),
          icon: "https://static.bimcc.com/openim/sheji05050E3D836A1AB0BF95EF2080AA9570.png",
          isActive: false,
          code: 3
        },
        {
          label: this.$t('components.Cooperation.059629-3'),
          icon: "https://static.bimcc.com/openim/shigongA16E1DF9754F820E4B2F9C2922E05C48.png",
          isActive: false,
          code: 5
        },
        {
          label: this.$t('components.Cooperation.059629-4'),
          icon: "https://static.bimcc.com/openim/jianli5BED6D0D5FD526ED0C0481CD08C9E48B.png",
          isActive: false,
          code: 2
        },
        {
          label: this.$t('components.Cooperation.059629-5'),
          icon: "https://static.bimcc.com/openim/kancha31C5C2CAB3212044931B58D14DB28EED.png",
          isActive: false,
          code: 4
        }
      ],
      typeList: null,
      typeForShow: null
    }
  },
  computed: {
    ...mapState(['targetArchi'])
  },
  watch: {
    targetArchi: {
      handler() {
        this.resetTypeList();
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.resetTypeList();
  },
  methods: {
    /**
     * @desc: 切换显示
     */
    onToggleShow() {
      this.showTypes = !this.showTypes;
      if (!this.typeList) {
        this.showTypes = false;
      }
      this.resetTypeList();
    },
    /**
     * @desc: 重置类型列表
     */
    resetTypeList() {
      this.typeForShow = null;
      this.typeList = null;
      const { external_company_type } = this.targetArchi || {};
      if (!external_company_type || !Array.isArray(external_company_type)) {
        return ;
      }
      const firstType = external_company_type?.[0];
      this.typeForShow = this.originData.find(ele => +ele.code === +firstType?.code);
      this.typeList = this.originData.map(ele => {
        return {
          ...ele,
          isActive: external_company_type.find(item => +item.code === ele.code)
        }
      });
    }
  }
}
</script>

<style lang="less" scoped>
.cooperation{
  padding: 0 10px;
  &:hover {
    background: rgba(255, 255, 255, .1);
  }
  .info-desc-box {
		display: block;
		box-sizing: border-box;
		padding: 5px 0;
		cursor: pointer;
		display: flex;
    .base-info{
      width: 100%;
      box-sizing: border-box;
      display: flex;
      .icon{
        height: 24px;
        width: 24px;
        padding-top: 8px;
        padding-right: 8px;
        border-radius: 8px;
        object-fit: cover;
      }
      .label{
        flex: 1;
        box-sizing: border-box;
        padding: 9px 0;
        height: 100%;
        font-size: 14px;
        font-family: var(--systemFontFamily);
        font-weight: 500;
        color: #121619;
        line-height: 22px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #fff;
      }
    }
    .el-tag{
      margin-top: 8px;
      margin-right: 5px;
    }
	}
  .cooperation-menu{
    position: fixed;
		right: 110px;
		z-index: 2999;
    width: 180px;
		box-sizing: border-box;
		box-shadow: 0px 5px 10px -1px rgba(0, 0, 0, 8%);
		transition: all .2s;
		overflow: hidden;
    background: #fff;
    border-radius: 6px;
    .cooperations{
      box-sizing: border-box;
      width: 100%;
      padding: 0 8px;
      .cooperation-section{
        box-sizing: border-box;
        padding: 8px 0;
        border-bottom: 1px solid #E9EBF0;
        &:last-of-type{
          border-bottom: 0;
        }
        .cooperation-item{
          display: flex;
          height: 40px;
          overflow: hidden;
          box-sizing: border-box;
          padding: 9px 10px;
          border-radius: 4px;
          cursor: pointer;
          &:hover{
            background: #E9EBF0;
          }
          .status{
            display: block;
            margin-top: 6px;
            height: 10px;
            width: 10px;
            margin-right: 15px;
            border-radius: 5px;
            background: #4D5358;
            &.project-width{
              background: #67C23A;
            }
          }
          .label{
            flex: 1;
            margin-left: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 14px;
            font-family: var(--systemFontFamily);
            font-weight: 400;
            color: #4D5358;
            line-height: 22px;
          }
        }
      }
    }
  }
  .cooperation-mask{
		position: fixed;
		height: 100vh;
		width: 100vw;
		background: transparent;
		top: 0;
		left: 0;
		z-index: 2998;
		cursor: pointer;
	}
}
</style>