<!--
 * @Description: 选择架构
 * @Author: luocheng
 * @Date: 2021-11-02 11:12:11
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-05-11 10:22:28
-->
<template>
	<div class="select-archi" :class="{'en-US' : $i18n.locale === 'en'}">
		<!-- 自定义实现 -->
		<section class="archi-desc-box" @click="onToggleShow" :style="archiDescBox">
			<i class="iconfont" :class="archiIcon" />
			<span class="archi-name">{{ targetArchi && (targetArchi.short_name || targetArchi.name) }}</span>
			<i v-if="!hideSelectArchi" :class="['iconfont', 'iconxiangshangjiantou1']"></i>
		</section>
		<!-- 内容 -->
		<article class="archi-select-menu"
			:style="{
				height: showSelect ? '496px' : '0px'
			}"
			v-loading="loading"
		>
			<template v-if="showSelect">
				<!-- 架构菜单 -->
				<section class="archi-nav-box">
					<!-- <h4 class="title" @click="entrySwitch">
						<i class="el-icon-back"></i>
						<span>入口切换</span>
					</h4> -->
					<ul class="archi-nav-list">
						<li class="archi-nav-item" v-for="nav in navList" :key="nav.key" @click="onNav(nav)"
							:class="{
								'active': nav.key === currentKey
							}"
						>
							<i :class="['iconfont', nav.icon]"></i>
							<p class="archi-nav-name">{{ $getLocalizedLabel({'zhLabel': nav.label, 'enLabel': nav.enLabel}) }}</p>
						</li>
					</ul>
				</section>
				<!-- 内容区域 -->
				<article class="archi-select-content"
					:class="{
						'withOutPB': currentKey === 'project'
					}"
				>
					<!-- 最近 -->
					<RecentView :historyArchi="historyArchi" v-if="currentKey === 'recent'" :reload="reload"></RecentView>
					<!-- {{ $t('index.SelectArchi.934493-1') }} -->
					<EnterpriseView v-if="currentKey === 'enterprise'" :reload="reload"></EnterpriseView>
					<!-- {{ $t('index.SelectArchi.934493-2') }} -->
					<ProjectView v-if="currentKey === 'project'" :reload="reload"></ProjectView>
				</article>
			</template>
		</article>
		<!-- mask -->
		<div class="select-archi-mask" v-show="showSelect" @click.self="onToggleShow"></div>
	</div>
</template>

<script>
import RecentView from './secondEdition/recent/Index';
import EnterpriseView from './secondEdition/enterprise/Index';
import ProjectView from './secondEdition/project/Index';
import { mapState } from 'vuex';
import { dataInterface } from '@/apis/data/index';
import eventBus from '@/plugins/eventBus';

export default {
	name: 'AelectArchi',
	components: {
		RecentView,
		EnterpriseView,
		ProjectView
	},
	props: {
		reload: {
			type: Boolean,
			required: false,
			default: false
		},
		archiDescBox: {
			type: Object,
			default: () => {
				return {
					padding: '0 16px'
				}
			}
		}
	},
	data() {
		return {
			// 侧边栏
			navList: [
				{
					key: 'recent',
					label: '最近查看',
					enLabel: 'Recently viewed',
					icon: 'icontime'
				},
				{
					key: 'enterprise',
					label: '企业',
					enLabel: 'Enterprise',
					icon: 'iconcompany'
				},
				{
					key: 'project',
					label: '项目',
					enLabel: 'Project',
					icon: 'iconproject'
				}
			],
			// 当前nav
			currentKey: 'recent',
			showSelect: false,
			// 最近登录
			historyArchi: {},
			loading: true,
			hideSelectArchi: false
		};
	},
	computed: {
		...mapState(['targetArchi']),
		// 架构图标
		archiIcon() {
			return this.targetArchi && this.targetArchi.archiType === 'company' ? 'iconcompany' : 'iconproject';
		}
	},
	created() {
		this.getHistoryArchi();
		const company = JSON.parse(localStorage.getItem('myCompany')) || [];
		const project = JSON.parse(localStorage.getItem('myProjects')) || [];
		if ((company.length + project.length) === 1) {
			this.hideSelectArchi = true
		}
	},
	mounted() {
		eventBus.$on('closeSelectArchi', () => {
			this.showSelect = false;
		});
	},
	methods: {
		/**
		 * @desc: 切换显示
		 */
		onToggleShow() {
			if (this.hideSelectArchi) {
				return
			}
			this.showSelect = !this.showSelect;
			this.currentKey = this.navList[0].key;
      this.$store.commit('setCacheArchi', null);
		},
		/**
		 * @desc: 点击菜单
		 * @param {Object} nav 侧边菜单对象
		 */
		onNav(nav) {
			this.currentKey = nav.key;
			// 取消当前选中的架构预览
      this.$store.commit('setCacheArchi', null);
		},
		/**
		 * @desc: 获取登录历史记录
		 */
		getHistoryArchi() {
			this.loading = true;
			dataInterface({
				__method_name__: 'globalFunctionCall',
				typeName: 'Archi',
				type: 'value',
				funcName: 'UserHistoryArchi'
			})
				.then((res) => {
					if (!res) return false;
          this.historyArchi = res.data.data || {};
					this.loading = false;
				})
				.catch((err) => {
					console.log(err);
					this.loading = false;
				});
		},
		/**
		 * @desc: 入口切换
		 */
		entrySwitch() {
			this.$router.push({
				name: 'SecondScreen'
			});
		}
	},
	beforeDestroy() {
		eventBus.$off('closeSelectArchi');
	}
};
</script>

<style lang="less" scoped>
@height: 32px;
@partWidth: 280px;
.select-archi {
	height: 52px;
	box-sizing: border-box;
	padding: 10px 0;
	// max-width: 200px;
	display: flex;
	justify-content: center;
	align-items: center;
	&:hover {
    background: rgba(255, 255, 255, .1);
	}
	.archi-desc-box {
		display: block;
		box-sizing: border-box;
		cursor: pointer;
		display: flex;
		.iconfont {
			line-height: 24px;
			font-size: 15px;
			font-family: var(--systemFontFamily);
			font-weight: 500;
			color: #ffffff;
		}
		.archi-name {
			flex: 1;
			font-size: 15px;
			line-height: 24px;
			font-size: 15px;
			font-family: var(--systemFontFamily);
			font-weight: 500;
			box-sizing: border-box;
			padding: 0 8px;
			color: #ffffff;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}
	.archi-select-menu{
		position: fixed;
		top: 54px;
		right: 5px;
		background: rgb(239, 241, 246);
		z-index: 2999;
		width: 720px;
		box-sizing: border-box;
		display: flex;
		box-shadow: 0px 5px 10px -1px rgba(0, 0, 0, 8%);
		transition: all .2s;
		overflow: hidden;
		border-radius: 2px;
		.archi-nav-box {
			width: 160px;
			box-sizing: border-box;
			padding: 20px 0;
			display: flex;
			flex-direction: column;
			background: #fff;
			border-radius: 4px;

			.title {
				line-height: 22px;
				font-size: 14px;
				font-family: var(--systemFontFamily);
				font-weight: 500;
				text-align: left;
				color: #161616;
				box-sizing: border-box;
				padding: 0 20px;
				margin-bottom: 20px;
				display: flex;
				cursor: pointer;
				i{
					line-height: 22px;
					margin-right: 5px;
					font-weight: bold;
				}
			}
			.archi-nav-list {
				flex: 1;
				.archi-nav-item {
					box-sizing: border-box;
					width: 100%;
					display: flex;
					height: 40px;
					padding: 9px 16px;
					cursor: pointer;
					i,
					p {
						font-size: 14px;
						font-family: PingFangSC, PingFangSC-Regular;
						font-weight: 400;
						text-align: left;
						color: #161616;
						line-height: 22px;
					}
					i {
						margin-right: 9px;
					}
					p {
						flex: 1;
					}
					&.active {
						background: linear-gradient(90deg,#ffffff, #e8f0fe);
						border-right: 4px solid var(--el-color-primary);
						i,
						p {
							color:  var(--el-color-primary);
						}
					}
				}
			}
		}
		.archi-select-content{
			height: 496px;
			flex: 1;
			overflow: hidden;
			background: rgb(239, 241, 246);
			box-sizing: border-box;
			padding: 16px 20px;
			&.withOutPB{
				padding-bottom: 0;
			}
		}
		.archi-part {
			width: @partWidth;
			height: 100%;
			box-sizing: border-box;
			padding: 16px 16px 10px 16px;
			overflow: hidden;
			display: flex;
			flex-direction: column;
			&.archi-info-part{
			}
			&.archi-list-part {
				// display: none;
				border-right: 1px solid rgb(226, 225, 226);
			}
		}
	}
	.select-archi-mask{
		position: fixed;
		height: 100vh;
		width: 100vw;
		background: transparent;
		top: 0;
		left: 0;
		z-index: 2998;
		cursor: pointer;
	}
}
.select-archi.en-US {
	.archi-nav-box {
		width: 170px;	
	}
	:deep(.archi-select-content) {
		.archi-infos,.enterprise,.project-main {
			.action-item  {
				height: 52px;
				.label {
					span {
						display: block;
					}
				}
			}
		}
	}
}

</style>